module.exports = {
    teamClassMap: {
        "Software": 'item-so',
        "Design and Analysis": "item-da",
        "Technical Communications": "item-tc",
        "Materials": "item-ma",
        "Mould": "item-mo",
        "Training": "item-tr",
        "Graphic Design": "item-gd",
        "General": "item-ge",
        "Executive": "item-ex",
        "Captain": "item-ca"
    }
}