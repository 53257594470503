import { WECCA_SOFTWARE_EMAIL } from "../config";

export const sendMail = (mailTo, submittedBy, emailBody, setSubmitMessage, formType, successMsg, failureMsg) => {
    window.Email.send({
        Host : "smtp.elasticemail.com",
        Username : "",
        Password : "",
        To : [mailTo, WECCA_SOFTWARE_EMAIL],
        From : "wecca.software@gmail.com",
        Subject : `The ${formType} form has been submitted by ${submittedBy}`,
        Body : emailBody
    }).then((message) => {
        if (message === 'OK') {
            setSubmitMessage(successMsg)
        } else {
            setSubmitMessage(failureMsg)
        }
    });
}

export const sendSignupEmail = (email, name, url) => {
    window.Email.send({
        Host : "smtp.elasticemail.com",
        Username : "",
        Password : "",
        To : [WECCA_SOFTWARE_EMAIL],
        From : "wecca.software@gmail.com",
        Subject : `${name} has signed up under username ${email}`,
        Body : `Please verify this user here: <a href=${url}>${url}</a>`
    })
}

export const sendConfirmationEmail = (email, name, url) => {
    window.Email.send({
        Host : "smtp.elasticemail.com",
        Username : "",
        Password : "",
        To : [email],
        From : "wecca.software@gmail.com",
        Subject : `You have signed up for an account on WECCA.org`,
        Body : `<p>Hi ${name}, you were signed up for an account on WECCA.org. 
            <br/><br/>If this was not you, please disregard this email or contact wecca.software@gmail.com.
            <br/><br/>Otherwise, please verify your email by logging in here: <a href=${url}>${url}</a>`
    })
}